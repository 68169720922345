export default [
  { singular: 'besöker [location] och [verb-singular]' },
  { singular: 'firar Norges självständighet från Sverige' },
  { singular: 'filmar [people] som [verb-singular]' },
  { singular: 'blandar ihop [location] med [location]' },
  { singular: 'flyger luftballong över [location]' },
  { singular: 'filar på en genial plan' },
  { singular: 'erkänner inbrott i kakburken' },
  { singular: 'åker motorcykel och blöder näsblod' },
  { singular: 'eldar skräp och tappar telefonen' },
  { singular: 'dukar upp för galej' },
  { singular: 'drejar en skål men det går åt helvete' },
  { singular: 'donerar [count-plural] kastruller till [people]' },
  { singular: 'använder munskyddet bakåfram' },
  { singular: 'håller lagomt avstånd till [count-plural] [people-plural]' },
  { singular: 'demonstrerar mot 5G med [people]' },
  { singular: 'dammsuger trottoaren utanför slottet' },
  { singular: 'bränner sig på kärlek' },
  { singular: 'bullrar så mycket att [count-plural] [subjective-plural] klagar' },
  { singular: 'broderar ett deppigt motiv' },
  { singular: 'botar [people]s torgskräck' },
  { singular: 'boxas med [people]' },
  { singular: 'borrar ett hål till grannen' },
  { singular: 'stoppar huvudet i sanden' },
  { singular: 'blinkar flörtigt med båda ögonen' },
  { singular: 'beräknar avståndet mellan Visby och Mount Doom' },
  { singular: 'behandlar alla lika' },
  { singular: 'driver ett bed & breakfast' },
  { singular: 'bakar franska bakelser' },
  { singular: 'backar från ett bråk' },
  { singular: 'avslöjar en konspiration' },
  { singular: 'avgår som premiärminister' },
  { singular: 'jobbar på ett bageri' },
  { singular: 'arbetar som brandman' },
  { singular: 'arbetar som polis' },
  { singular: "rakar [people]'s ben" },
  { singular: 'gifter sig med [people]' },
  { singular: 'jobbar som fönsterputsare hos [people]' },
  { singular: 'hittar smulor i sängen på bröllopsnatten' },
  { singular: 'diskuterar olika sätt att använda högerhanden' },
  { singular: "tar skydd från regnet under [people]'s hatt" },
  { singular: 'hoppar fallskärm men fastnar i Turning Torso' },
  { singular: 'fyller råttfällorna med delikatessost' },
  { singular: 'har tomtar på loftet' },
  { singular: 'kittlar [people]' },
  { singular: '[verb-singular] och ramlar ned för en trapp' },
  { singular: '[verb-singular] och snubblar på ett bananskal' },
  { singular: '[verb-singular] och skriver en arg lapp' },
  { singular: '[verb-singular] och somnar' },
  { singular: '[verb-singular] och vaknar' },
  { singular: '[verb-singular] och snubblar på katten Jansson' },
  { singular: 'viker servettsvanar' },
  { singular: 'vaskar champagne på [people]' },
  { singular: 'väntar på bussen med [people]' },
  { singular: 'välter kossor' },
  { singular: 'utövar regndans' },
  { singular: 'tweetar om sin nya tröja' },
  { singular: 'trampar på en legobit' },
  { singular: 'träffar en polsk pojke och [verb-singular]' },
  { singular: 'träffar en finlandsvensk och [verb-singular]' },
  { singular: 'testar en kilt och gillar det' },
  { singular: 'tävlar mot [people] i lilla sportspegeln' },
  { singular: 'tar traktorn till julmarknaden' },
  { singular: 'tar pendeltåget till Visby hamn' },
  { singular: 'tar bussen till moppeträffen' },
  { singular: 'talar med hästar' },
  { singular: 'syr en sjöstjärna' },
  { singular: 'sveper en flaska apotekarnes påskmust' },
  { singular: 'stirrar döden i vitögat' },
  { singular: 'steker bacon med Miss Piggy' },
  { singular: 'står på händer på toppen av kaknästornet' },
  { singular: 'städar bilen' },
  { singular: 'springer maraton' },
  { singular: 'spenderar julafton med [people]' },
  { singular: 'spelar sällskapsspel med [people] och [people]' },
  { singular: 'spelar panflöjt' },
  { singular: 'spelar på hästar' },
  { singular: 'spelar Mario Party' },
  { singular: 'spelar Guitar Hero' },
  { singular: 'spelar fussball med [people]' },
  { singular: 'spelar datorspel' },
  { singular: 'spelar curling och [verb-singular]' },
  { singular: 'spelar bowling' },
  { singular: 'spelar Angry Birds' },
  { singular: 'sopar sina bekymmer under mattan' },
  { singular: 'somnar under fallskärmshoppet' },
  { singular: 'solar på stranden' },
  { singular: 'snickrar en tv bänk' },
  { singular: 'smyger sig upp bakom [people]' },
  { singular: 'smakar på bröllopstårtan' },
  { singular: 'slår highscore i tetris' },
  { singular: 'skrattar sig medvetslös' },
  { singular: 'skedar med [people]' },
  { singular: 'sjunger i duschen' },
  { singular: 'sitter på bussen och mimar till "Oops, i did it again"' },
  { singular: 'sitter i knä på jultomten i en galleria' },
  { singular: 'simmar över Göta kanal' },
  { singular: 'serverar hummer till [people]' },
  { singular: 'saknar att vara lilla skeden' },
  { singular: 'river hela kåken' },
  { singular: 'rider på ett tåg' },
  { singular: 'rider på en ponny' },
  { singular: 'rensar toaletten' },
  { singular: 'rensar ogräs' },
  { singular: 'plankar på frihetsgudinnan' },
  { singular: 'pantar burkar' },
  { singular: 'orsakar en jordbävning' },
  { singular: 'örfilar [people]' },
  { singular: 'bär en soffa med [people]' },
  { singular: 'matar duvor i Paris' },
  { singular: 'målar huset i regnbågens färger' },
  { singular: 'målar båten' },
  { singular: 'målar av [people]' },
  { singular: 'lyssnar på hiphop' },
  { singular: 'löser en rubiks kub' },
  { singular: 'letar efter en nål i en höstack' },
  { singular: 'letar efter [people] men hittar ett diskberg' },
  { singular: 'leker elektriker' },
  { singular: 'läser om kärnkraftsverk' },
  { singular: 'lagar en köttgryta' },
  { singular: 'lagar broccolipaj' },
  { singular: 'kryper runt i skyttegravarna under andra världskriget' },
  { singular: 'krockar med blodbussen' },
  { singular: 'krockar med [people] i Kungens kurva' },
  { singular: 'kör go-cart' },
  { singular: 'kör beer pong mot [people]' },
  { singular: 'klyver ved' },
  { singular: 'klipper gräset' },
  { singular: 'hoppar hopprep' },
  { singular: 'hoppar bungyjump från pyramiderna i Giza' },
  { singular: 'hjälper [people] att peta i näsan' },
  { singular: 'hittar ett påskägg' },
  { singular: 'har tappat hemnyckeln' },
  { singular: 'har sålt smöret och tappat pengarna' },
  { singular: 'har för långa kryckor' },
  { singular: 'har ett kundvagnsrace' },
  { singular: 'hänger tvätt' },
  { singular: 'gurglar munskölj' },
  { singular: 'grillar marschmallows över öppen spis' },
  { singular: 'gräver sin egen grav' },
  { singular: 'gör rent skorstenen' },
  { singular: 'gör marmelad' },
  { singular: 'gör en kullerbytta på Mount Everest' },
  { singular: 'gör [people] en björntjänst' },
  { singular: 'ger [people] en wet willy' },
  { singular: 'går på muggen' },
  { singular: 'går på kräftskiva och sjunger snapsvisor' },
  { singular: 'går på julottan i Uppsala Domkyrka' },
  { singular: 'går åt fel håll i rulltrappan' },
  { singular: 'fyller år' },
  { singular: 'frossar friterad kyckling' },
  { singular: 'flyger till spanien' },
  { singular: 'firar sin födelsedag med [people]' },
  { singular: 'fastnar i hissen med [people]' },
  { singular: 'fastnar i en cell i Fångarna på Fortet' },
  { singular: 'får medalj för deltagande' },
  { singular: 'får kramp i låret' },
  { singular: 'får beundrarbrev från [people]' },
  { singular: 'faller för ett nigeriabrev' },
  { singular: 'dricker kinesiskt te' },
  { singular: 'diskuterar vädret' },
  { singular: 'dansar tango med [people]' },
  { singular: 'dansar i ett godisregn' },
  { singular: 'dansar gangnam style och [verb-singular]' },
  { singular: 'dansar balett' },
  { singular: 'dammsuger kungliga slottet' },
  { singular: 'cyklar vättern runt' },
  { singular: 'cyklar till kina' },
  { singular: 'cyklar enhjuling' },
  { singular: 'bygger en trädkoja' },
  { singular: 'bygger en stenmur' },
  { singular: 'bygger en altan' },
  { singular: 'bryter foten och går till doktorn' },
  { singular: 'bråkar om en muffin med [people]' },
  { singular: 'borstar tänderna med en kvast' },
  { singular: 'bor i en soptunna' },
  { singular: 'träffas av Amors pilar, blir kär i [people]' },
  { singular: 'blir tacklad i amerikansk fotboll' },
  { singular: 'blir kär i en foppatoffel' },
  { singular: 'blir biten av en vampyr' },
  { singular: 'bjuder på rostat bröd i Halv åtta hos mig' },
  { singular: 'bjuder [people] på tårta' },
  { singular: 'bildar hög med [people] och [people]' },
  { singular: 'betalar [people] för en hatt' },
  { singular: 'besöker Nordkoreas ambassad' },
  { singular: 'besöker Karlskrona' },
  { singular: 'besöker Island och dyker i gejsrar' },
  { singular: 'besöker en rymdstation' },
  { singular: 'bakar lussekatter' },
  { singular: 'badar isvak' },
  { singular: 'badar i turkisk yogurt och får en kallsup' },
  { singular: 'badar i nutella' },
  { singular: 'äter sill med [people]' },
  { singular: 'äter lampa och blir lös i magen' },
  { singular: 'äter kanelbullar' },
  { singular: 'äter godis på en vardag' },
  { singular: 'arresterar [people]' },
  { singular: 'använder en kalsong som hatt' },
  { singular: 'ansöker om jobb på Burger King' },
  { singular: 'älskar [people] på avstånd' },
  { singular: 'åker vattenskidor' },
  { singular: 'åker tillbaka till framtiden' },
  { singular: 'åker till jönköping och köper en guldfisk' },
  { singular: 'åker finlandsfärja och strular med [people]' },
  { singular: 'åker berg-och-dalbana' },
  { singular: '[verb-singular] och hamnar i fängelse' },
  { singular: 'äter en [subjective-singular] med bröd' },
  { singular: 'jonglerar med tre [subjective-plural]' },
  { singular: 'fyller en ryggsäck med [subjective-plural]' },
  { singular: 'experimenterar på [subjective-plural]' },
  { singular: 'flamberar [subjective-plural]' },
  { singular: 'bloggar om [subjective-plural]' },
  { singular: 'föreläser om [subjective-plural]' },
  { singular: 'gräver ett hål och fyller det med [subjective-plural]' },
  { singular: 'gör en ansiktsmask av krossade [subjective-plural]' },
  { singular: 'twerkar till en låt av Carola' },
  { singular: 'sjunger karaoke med en använd toaborste' },
  { singular: 'flexar sina magmuskler' },
  { singular: 'bearbetare trauman från barndomen', adult: true },
  { singular: 'förlorar Biggest Loser', adult: true },
  { singular: 'filosoferar över sambandet mellan ringmuren och ringmuskeln', adult: true },
  { singular: 'kommer sist i handikapp-OS', adult: true },
  { singular: 'spenderar en natt i fyllecell', adult: true },
  { singular: 'och [people] vill vänta till bröllopsnatten', adult: true },
  { singular: 'kan inte hitta klitoris', adult: true },
  { singular: 'föreslår att festen ska testa leken "runka bulle"', adult: true },
  { singular: 'äter endast muffins på tjockislägret', adult: true },
  { singular: 'tycker porr blir bättre tillsammans', adult: true },
  { singular: 'hittar könshår i milkshaken', adult: true },
  { singular: 'begår kamikaze', adult: true },
  { singular: "och [people]'s kuddkrig spårar ur till ett kärnvapenkrig", adult: true },
  { singular: 'har inte bara en pappa', adult: true },
  { singular: 'slår sönder piñatan men den innehåller bara [subjective-plural]', adult: true },
  { singular: 'gör snöänglar i gul snö', adult: true },
  { singular: 'provar cybersex men blir inte nöjd', adult: true },
  { singular: 'har sex med siamesiska tvillingar', adult: true },
  { singular: 'dricker hembränt och ser på melodifestivalen', adult: true },
  { singular: 'hittar inte g-punkten på kvart-i-tre-ragget', adult: true },
  { singular: 'besöker ett gloryhole för att dansa parningsdans', adult: true },
  { singular: 'tar med den heliga bibeln till sovrummet', adult: true },
  { singular: 'väljer alltid tandemcykeln före menstruationscykeln', adult: true },
  { singular: 'blandar ihop vattentortyr med fontänorgasm', adult: true },
  { singular: 'träffar en ko, får mjölkmustasch', adult: true },
  { singular: 'upptäcker amfetamin hemma hos [people]', adult: true },
  { singular: 'spelar klädpoker med fyra [subjective-plural]', adult: true },
  { singular: "kan inte sluta stirra på [people]'s dubbelhaka", adult: true },
  { singular: 'hittar Lady Gagas köttklänning i underklädeslådan', adult: true },
  { singular: 'kämpar mot sura uppstötningar', adult: true },
  { singular: 'försöker bota sin analklåda med vaselin', adult: true },
  { singular: "är [people]'s troféfru", adult: true },
  { singular: 'har halva inne på [people]', adult: true },
  { singular: 'vill bara ha hårda paket', adult: true },
  { singular: 'dricker blåbärssoppa under lingonveckan', adult: true },
  { singular: 'smaskar på snorkråkor', adult: true },
  { singular: 'besöker Globen med en bomb runt midjan', adult: true },
  { singular: 'försöker dölja ståndet under dansen med mormor', adult: true },
  { singular: 'tvingas amputera örsnibben efter en LAN-olycka', adult: true },
  { singular: 'badar bastu med håriga [subjective-plural]', adult: true },
  { singular: 'går walk of shame från firmafesten', adult: true },
  { singular: 'vaknar med ett hästhuvud under täcket', adult: true },
  { singular: 'tar självmord med en osthyvel', adult: true },
  { singular: "gillar [people]'s stjärt", adult: true },
  { singular: 'tar segwayen till AA-mötet', adult: true },
  { singular: 'lockar en hund med något smaskigt på könet', adult: true },
  { singular: 'föredrar hemgjord mjukporr på VHS', adult: true },
  { singular: 'vill endast ha levande djur på grillen', adult: true },
  { singular: 'lider av pung längre än penis', adult: true },
  { singular: 'tappar bort farfars aska', adult: true },
  { singular: 'offrar sin förstfödde', adult: true },
  { singular: 'snortar hockeypulver', adult: true },
  { singular: 'tränar bort kräkreflexen', adult: true },
  { singular: 'har sex med en äppelpaj', adult: true },
  { singular: 'använder pissoaren men tappar kontrollen', adult: true },
  { singular: 'piercar könet med en rostig köttermometer', adult: true },
  { singular: "spetsar [people]'s drink med rohypnol", adult: true },
  { singular: 'twerkar på ett hustak', adult: true },
  { singular: 'förnekar förintelsen', adult: true },
  { singular: 'skrattar åt en glasögonorm', adult: true },
  { singular: 'spelar klädpoker med [count-plural] [subjective-plural]', adult: true },
  { singular: 'leker nakenlekar med [people]', adult: true },
  { singular: 'sparar sitt öronvax i en burk under sängen', adult: true },
  { singular: "tatuerar [people]'s namn på insidan av läppen", adult: true },
  { singular: 'tillfredsställer [people] med en [subjective-singular]', adult: true },
  { singular: 'gillar stringtrosor', adult: true },
  { singular: 'förfestar med [count-plural] [subjective-plural]', adult: true },
  { singular: 'blir inspirerad av gatuvåld', adult: true },
  { singular: 'blir tänd av att korsfästa [subjective-plural]', adult: true },
  { singular: 'spelar brevlåde-baseball', adult: true },
  { singular: 'kräks av [people]', adult: true },
  { singular: 'slampar till sig', adult: true },
  { singular: 'skär upp handlederna med en smörkniv', adult: true },
  { singular: 'känner resultatet av laxermedel', adult: true },
  { singular: 'byter vuxenblöja på [people]', adult: true },
  { singular: 'har ett rullstolsrally mot [people] och [people]', adult: true },
  { singular: 'dör diarrédöden', adult: true },
  { singular: 'går på korvfest med [people] och [people]', adult: true },
  { singular: 'tar viagra', adult: true },
  { singular: 'går på tarmundersökning', adult: true },
  { singular: 'deltar i en ny realityserie som handlar om [subjective-singular]', adult: true },
  { singular: 'skriver "Gratis godis" på skåpbilen', adult: true },
  { singular: 'går vilse i Hitlers bunker', adult: true },
  { singular: 'drabbas av Ebola', adult: true },
  { singular: 'bedriver organhandel med lammkött', adult: true },
  { singular: 'får akut vinterkräksjuka i slalombacken', adult: true },
  { singular: 'spottar i motvind', adult: true },
  { singular: 'faller för grupptrycket och [verb-adult]', adult: true },
  { singular: 'rakar pungen med en trasig iPhone-skärm', adult: true },
  { singular: 'ser en häst trava förbi och låter morgonrocken glida upp', adult: true },
  { singular: 'bjuder [people] på ett par smurfhits i tortyrkammaren', adult: true },
  { singular: 'försöker fly till snickeboa men [verb-singular]', adult: true },
  { singular: 'snubblar och sväljer tungan', adult: true },
  { singular: 'fastnar i kompiszonen men hittar öppet förhållande', adult: true },
  { singular: 'groggar på botox och vaselin', adult: true },
  { singular: 'gräver bort Skåne', adult: true },
  { singular: 'ammar [people]', adult: true },
  { singular: 'försöker ta simborgarmärket men drunknar i champagne', adult: true },
  { singular: 'köper kondomer med smak av banan', adult: true },
  { singular: 'fantiserar om [people] ', adult: true },
  { singular: 'får fittkramp', adult: true },
  { singular: 'äger [count-plural] [subjective-plural] efter att ha slickat på dem', adult: true },
  { singular: 'säljer använda speedos till högsta bjudande', adult: true },
  { singular: 'dör och blir ett skelett i garderoben', adult: true },
  { singular: 'hoppar från Eiffeltornet', adult: true },
  { singular: 'firar den 11e september', adult: true },
  { singular: 'klurar på hur man har sex med en delfin', adult: true },
  { singular: 'har sex med ett pringlesrör', adult: true },
  { singular: 'skapar magi med sina höfter', adult: true },
  { singular: "fantiserar om [people]'s orgasmansikte", adult: true },
  { singular: 'bryter kuken under badmintonmatchen', adult: true },
  { singular: 'ger bort en penisavgjutning i julklapp', adult: true },
  { singular: "imponeras av [people]'s tredje ben", adult: true },
  { singular: 'och [people] utnyttjar kärlekstunneln till fullo', adult: true },
]
