import data from './data/'

const structures = [
  ['count', 'adjective', 'subjective', 'verb-singular'],
  ['count', 'subjective', 'verb-singular'],
  ['adjective-singular', 'people', 'verb-singular'],
  ['people', 'verb-singular'],
]

export function generateCharade(includeAdult) {
  const structure = randomArrayItem(structures)

  const plural = Math.random() < 0.5
  const generatedCharade = structure.reduce((charade, partType, idx, arr) => {
    let part = partGenerator(partType, plural, includeAdult)

    part = replaceChildParts(part, plural, includeAdult)

    if (idx === 0) return part
    return `${charade} ${part}${idx === arr.length - 1 ? '.' : ''}`
  }, '')

  return capitalizeFirstLetter(generatedCharade)
}

const partGenerator = (type, plural, includeAdult) => {
  const [typeName, modifier] = type.split('-')

  let onlyAdult = false
  switch (modifier) {
    case 'singular':
      plural = false
      break
    case 'plural':
      plural = true
      break
    case 'adult':
      onlyAdult = true
      break
    default:
      break
  }

  const parts = onlyAdult
    ? data[typeName].filter((p) => p.adult)
    : data[typeName].filter((p) => includeAdult || !p.adult)
  let part = randomArrayItem(parts)

  let charadePart = ''
  if (typeof part === 'string') charadePart = part
  else charadePart = plural ? part.plural : part.singular

  if (Array.isArray(charadePart)) charadePart = randomArrayItem(charadePart)

  return charadePart
}

const randomArrayItem = (array) => array[Math.floor(Math.random() * array.length)]

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

const replaceChildParts = (part, plural, includeAdult) => {
  const rgx = /\[(.*?)\]/
  let match = rgx.exec(part)
  while (match) {
    part = part.replace(rgx, partGenerator(match[1], plural))
    match = rgx.exec(part)
  }

  return part
}
