export default [
  'Scooby-Doo',
  'Mr. Bean',
  'Olof Palme',
  'Iron Man',
  'Captain America',
  'Gunde Svan',
  'Ernst Kirchsteiger',
  'Anders Bagge',
  'Laila Bagge',
  'Alexander Bard',
  'Arga snickaren',
  'Filip och Fredrik',
  'David Batra',
  'Magnus Betnér',
  'Terminator',
  'John Blund',
  'Döden',
  'Carolina Klüft',
  'Peter Forsberg',
  'Napoleon',
  'Jesus',
  'Stephen Hawkins',
  'Skalman',
  'Aladin',
  'Baloo',
  'Mowgli',
  'Pluto',
  'Bambi',
  'Simba',
  'Cruella de Vil',
  'Shrek',
  'Mike Wazowsky',
  'Nemo',
  'Sir Väs',
  'Jabba the Hut',
  'Godzilla',
  'King Kong',
  'Michael Jordan',
  'Johnny Bravo',
  'Bane',
  'Mikael Persbrandt',
  'Harry Potter',
  'Emil i Lönneberga',
  'Quasimodo',
  'Lady Gaga',
  'Pikachu',
  'Grodan Boll',
  'Peter Griffin',
  'Charlie Chaplin',
  'J-O Waldner',
  'Prins Carl-Philip',
  'Prinsessan på ärten',
  'De tre små grisarna',
  'Djungel-George',
  'Houdini',
  'Picasso',
  'Katten Gustaf',
  'Snövit',
  'Askungen',
  'Kapten Krok',
  'Hamlet',
  'Neil Armstrong',
  'Buffy',
  'Dumbo',
  'Frankenstein',
  'Rödluvan',
  'Mario',
  'Tarzan',
  'Zorro',
  'Supermannen',
  'Mästerkatten i stövlar',
  'Guldlock',
  'Barbie',
  'Törnrosa',
  'Snobben',
  'Shakespeare',
  'Albert Einstein',
  'Sherlock Holmes',
  'Djävulen',
  'Indiana Jones',
  'James Bond',
  'Gollum',
  'Frodo',
  'Yoda',
  'Darth Vader',
  'Ace Ventura',
  'WALL-E',
  'Dracula',
  'Svampbob Fyrkant',
  'Ariel',
  'Tintin',
  'Peter Pan',
  'Tingeling',
  'Mumin',
  'Hulken',
  'Spindelmannen',
  'Alfons Åberg',
  'Nalle Puh',
  'Ior',
  'David Hasselhoff',
  'Homer Simpson',
  'Bart Simpson',
  'Robin Hood',
  'Linus på linjen',
  'Pippi Långstrump',
  'Roger Pontare',
  'Beyoncé',
  'Jerry Williams',
  'Robert Gustafsson',
  'Christer Björkman',
  'Zlatan',
  'Stig-Helmer Olsson',
  'Thomas Di Leva',
  'Kung Carl XVI Gustaf',
  'Musse Pigg',
  'Kalle Anka',
  'Bamse',
  'Gunilla Persson (housewifes)',
  'Barack Obama',
  'George W. Bush',
  'Elvis Presley',
  'gorillan Koko',
  'Carola',
  'Gud',
  'Påskharen',
  'Skäggiga damen',
  'Jultomten',
  'Göran Persson',
  'Fred Flintstone',
  'Arnold Schwarznegger',
  'Läderlappen',
  'E.T',
  'Michael Jackson',
  'MacGyver',
  'Zeus',
  'Darth Vader',
  'Kakmonstret',
  'Moses',
  'Jesus',
  'Lucia',
  'Stora stygga vargen',
  'Påven',
  'Snurre Sprätt',
  'Jackie Chan',
  'Benjamin Syrsa',
  'Joakim von Anka',
  'Toker',
  'Trötter',
  'Blyger',
  'Kloker',
  'Prosit',
  'Butter',
  'Glader',
  'Pinocchio',
  'Blixten McQueen',
  'Buzz Lightyear',
  'Mike Wazowski',
  'Serena Williams',
  'Annika Sörenstam',
  'Michael Phelps',
  'Usain Bolt',
  'Leonardo Da Vinci',
  'Abraham Lincoln',
  'Marilyn Monroe',
  'Rosa Parks',
  'Martin Luther King',
  'Luke Skywalker',
  'Samwise Gamgee',
  'Rapunzel',
  'Forrest Gump',
  'Britney Spears',
  'Mozart',
  'Anders Tegnell',
  'Kobe Bryant',
  'Chadwick Boseman',
  'Diego Maradona',
  'Daenerys Targaryen',
  'Röjar-Ralf',
  'Hälge',
  'Rocky',
  'Pumbaa',
  'Långben',
  'Spöket Laban',
  'Gaston',
  'Gammelsmurf',
]
