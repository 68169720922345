import React from 'react'
import './index.css'
import { useCurrentRoute, useViewElement } from 'react-navi'
import { useTransition, animated } from 'react-spring'

export default function App() {
  let currentRoute = useCurrentRoute()
  let viewElement = useViewElement()
  let transitions = useTransition(viewElement, currentRoute.url.href, {
    from: { opacity: 0, transform: 'scale(1) translateY(-50%)' },
    enter: { opacity: 1, transform: 'scale(1) translateY(0)' },
    leave: { opacity: 0, transform: 'scale(0) translateY(50%)' }
  })

  return transitions.map(({ item, props: style, key }) => (
    <animated.div
      key={key}
      style={{
        ...style,
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%'
      }}
    >
      {item}
    </animated.div>
  ))
}
