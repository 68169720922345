export default [
  'Norsk-Svenska gränsen',
  'Kinesiska Muren',
  'Stockholms skärgård',
  'Demilitariserade zonen i Nordkorea',
  'Tyskland',
  'Göteborg',
  'Södertälje',
  'Abisko',
  'Berlin',
  'Paris',
  'Los Angeles',
  'Texas',
  'Eiffeltornet',
  'lutande tornet i Pisa',
  'Medelhavet',
  'Antarktis',
]
