export default [
  { singular: 'veterinär', plural: 'veterinärer' },
  { singular: 'utvandrare', plural: 'utvandrare' },
  { singular: 'dvärg', plural: 'dvärgar' },
  { singular: 'frisör', plural: 'frisörer' },
  { singular: 'hippie', plural: 'hippies' },
  { singular: 'pilot', plural: 'piloter' },
  { singular: 'butler', plural: 'butlers' },
  { singular: 'apa', plural: 'apor' },
  { singular: 'cowboy', plural: 'cowboys' },
  { singular: 'indian', plural: 'indianer' },
  { singular: 'drake', plural: 'drakar' },
  { singular: 'myra', plural: 'myror' },
  { singular: 'backhoppare', plural: 'backhoppare' },
  { singular: 'strandskata', plural: 'strandskator' },
  { singular: 'enarmad bandit', plural: 'enarmad banditer' },
  { singular: 'långtradarchaufför', plural: 'långtradarchaufförer' },
  { singular: 'bondkatt', plural: 'bondkatter' },
  { singular: 'groda', plural: 'grodor' },
  { singular: 'polis', plural: 'poliser' },
  { singular: 'uggla', plural: 'ugglor' },
  { singular: 'snögubbe', plural: 'snögubbar' },
  { singular: 'gorilla', plural: 'gorillor' },
  { singular: 'smurf', plural: 'smurfar' },
  { singular: 'hund', plural: 'hundar' },
  { singular: 'hamster', plural: 'hamstrar' },
  { singular: 'militär', plural: 'militärer' },
  { singular: 'säl', plural: 'sälar' },
  { singular: 'student', plural: 'studenter' },
  { singular: 'elefant', plural: 'elefanter' },
  { singular: 'räv', plural: 'rävar' },
  { singular: 'pirat', plural: 'pirater' },
  { singular: 'sjörövare', plural: 'sjörövare' },
  { singular: 'pojke', plural: 'pojkar' },
  { singular: 'pingvin', plural: 'pingviner' },
  { singular: 'brandman', plural: 'brandmän' },
  { singular: 'zombie', plural: 'zombies' },
  { singular: 'präst', plural: 'präster' },
  { singular: 'munk', plural: 'munkar' },
  { singular: 'varg', plural: 'vargar' },
  { singular: 'sjöman', plural: 'sjömän' },
  { singular: 'läkare', plural: 'läkare' },
  { singular: 'sheriff', plural: 'sheriffer' },
  { singular: 'patient', plural: 'patienter' },
  { singular: 'cirkusdirektör', plural: 'cirkusdirektörer' },
  { singular: 'krokodil', plural: 'krokodiler' },
  { singular: 'viking', plural: 'vikingar' },
  { singular: 'sumobrottare', plural: 'sumobrottare' },
  { singular: 'dinosaurie', plural: 'dinosaurier' },
  { singular: 'samurai', plural: 'samuraier' },
  { singular: 'gris', plural: 'grisar' },
  { singular: 'alien', plural: 'aliens' },
  { singular: 'fotbollsdomare', plural: 'fotbollsdomare' },
  { singular: 'programmerare', plural: 'programmerare' },
  { singular: 'sminkös', plural: 'sminköser' },
  { singular: 'robot', plural: 'robotar' },
  { singular: 'rockstjärna', plural: 'rockstjärnor' },
  { singular: 'jägare', plural: 'jägare' },
  { singular: 'rörmokare', plural: 'rörmokare' },
  { singular: 'kock', plural: 'kockar' },
  { singular: 'astronaut', plural: 'astronauter' },
  { singular: 'ko', plural: 'kossor' },
  { singular: 'professor', plural: 'professorer' },
  { singular: 'urmakare', plural: 'urmakare' },
  { singular: 'haj', plural: 'hajar' },
  { singular: 'häst', plural: 'hästar' },
  { singular: 'struts', plural: 'strutsar' },
  { singular: 'giraff', plural: 'giraffer' },
  { singular: 'balettdansös', plural: 'balettdansöser' },
  { singular: 'gladiator', plural: 'gladiatorer' },
  { singular: 'brevbärare', plural: 'brevbärare' },
  { singular: 'vaktmästare', plural: 'vaktmästare' },
  { singular: 'ekonom', plural: 'ekonomer' },
  { singular: 'målare', plural: 'målare' },
  { singular: 'dagisfröken', plural: 'dagisfröknar' },
  { singular: 'sopgubbe', plural: 'sopgubbar' },
  { singular: 'uteliggare', plural: 'uteliggare' },
  { singular: 'igelkott', plural: 'igelkottar' },
  { singular: 'skomakare', plural: 'skomakare' },
  { singular: 'tjej', plural: 'tjejer' },
  { singular: 'mormor', plural: 'mormödrar' },
  { singular: 'magdansös', plural: 'magdansöser' },
  { singular: 'paragrafryttare', plural: 'paragrafryttare' },
  { singular: 'bödel', plural: 'bödlar' },
  { singular: 'ren', plural: 'renar' },
  { singular: 'sjöhäst', plural: 'sjöhästar' },
  { singular: 'sjöstjärna', plural: 'sjöstjärnor' },
  { singular: 'enhörning', plural: 'enhörningar' },
  { singular: 'prinsessa', plural: 'prinsessor' },
  { singular: 'kentaur', plural: 'kentaurer' },
  { singular: 'mumie', plural: 'mumier' },
  { singular: 'vampyr', plural: 'vampyrer' },
  { singular: 'varulv', plural: 'varulvar' },
  { singular: 'trollkarl', plural: 'trollkarlar' },
  { singular: 'svetsare', plural: 'svetsare' },
  { singular: 'pizzabagare', plural: 'pizzabagare' },
  { singular: 'backpacker', plural: 'backpackers' },
  { singular: 'cyklist', plural: 'cyklister' },
  { singular: 'cirkusartist', plural: 'cirkusartister' },
  { singular: 'prisjägare', plural: 'prisjägare' },
  { singular: 'fågelskådare', plural: 'fågelskådare' },
  { singular: 'slaktare', plural: 'slaktare' },
  { singular: 'fönsterputsare', plural: 'fönsterputsare' },
  { singular: 'mimare', plural: 'mimare' },
  { singular: 'fladdermus', plural: 'fladdermöss' },
  { singular: 'galenpanna', plural: 'galenpannor' },
  { singular: 'lärare', plural: 'lärare' },
  { singular: 'val', plural: 'valar' },
  { singular: 'björn', plural: 'björnar' },
  { singular: 'fjäril', plural: 'fjärilar' },
  { singular: 'isbjörn', plural: 'isbjörnar' },
  { singular: 'valp', plural: 'valpar' },
  { singular: 'kameleont', plural: 'kameleonter' },
  { singular: 'tupp', plural: 'tuppar' },
  { singular: 'bäbis', plural: 'bäbisar' },
  { singular: 'clown', plural: 'clownar' },
  { singular: 'ninja', plural: 'ninjor' },
  { singular: 'spöke', plural: 'spöken' },
  { singular: 'drake', plural: 'drakar' },
  { singular: 'höna', plural: 'höns' },
  { singular: 'ponny', plural: 'ponnys' },
  { singular: 'mammut', plural: 'mammutar' },
  { singular: 'tjur', plural: 'tjurar' },
  { singular: 'utomjording', plural: 'utomjordingar' },
  { singular: 'älg', plural: 'älgar' },
  { singular: 'känguru', plural: 'kängurus' },
  { singular: 'kattunge', plural: 'kattungar' },
  { singular: 'mus', plural: 'möss' },
  { singular: 'detektiv', plural: 'detektiver' },
  { singular: 'sköldpadda', plural: 'sköldpaddor' },
  { singular: 'panda', plural: 'pandor' },
  { singular: 'myrslok', plural: 'myrslokar' },
  { singular: 'vandrande pinne', plural: 'vandrande pinnar' },
  { singular: 'guldfisk', plural: 'guldfiskar' },
  { singular: 'dalmatin', plural: 'dalmatiner' },
  { singular: 'tandläkare', plural: 'tandläkare' },
  { singular: 'kock', plural: 'kockar' },
  { singular: 'sjuksköterska', plural: 'sjuksköterskor' },
  { singular: 'enhörning', plural: 'enhörningar' },
  { singular: 'trädgårdstomte', plural: 'trädgårdstomtar' },
  { singular: 'masochist', plural: 'masochister', adult: true },
  { singular: 'bimbo', plural: 'bimbos', adult: true },
  { singular: 'prostituerad', plural: 'prostituerade', adult: true },
  { singular: 'spermie', plural: 'spermier', adult: true },
  { singular: 'snuskgubbe', plural: 'snuskgubbar', adult: true },
  { singular: 'analfabet', plural: 'analfabeter', adult: true },
  { singular: 'rumpnisse', plural: 'rumpnissar', adult: true },
  { singular: 'nekrofil', plural: 'nekrofiler', adult: true },
  { singular: 'oskuld', plural: 'oskulder', adult: true },
  { singular: 'föräldralöst barn', plural: 'föräldralösa barn', adult: true },
  { singular: 'strippa', plural: 'strippor', adult: true },
  { singular: 'schlagerbög', plural: 'schlagerbögar', adult: true },
  { singular: 'truckflata', plural: 'truckflator', adult: true },
  { singular: 'porrsurfare', plural: 'porrsurfare', adult: true },
  { singular: 'porrstjärna', plural: 'porrstjärnor', adult: true },
  { singular: 'muslim', plural: 'muslimer', adult: true },
  { singular: 'kristen', plural: 'kristna', adult: true },
  { singular: 'buddhist', plural: 'buddhister', adult: true },
  { singular: 'rabiesräv', plural: 'rabiesrävar', adult: true },
  { singular: 'transvestit', plural: 'transvestiter', adult: true },
  { singular: 'vit kränkt man', plural: 'vita kränkta män', adult: true },
  { singular: 'feminist', plural: 'feminister', adult: true },
  { singular: 'toalettdykare', plural: 'toalettdykare', adult: true },
  { singular: 'person som stammar', plural: 'personer som stammar', adult: true },
  { singular: 'dyslektiker', plural: 'dyslektiker', adult: true }
]
