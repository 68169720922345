import styled from 'styled-components'

export const DefaultButton = styled.button`
  cursor: pointer;
  padding: 12px 36px;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  font-family: 'Kavoon', cursive;
  border: 2px dashed rgba(255, 255, 255, 0.3);
  border-radius: 5px;

  &:hover {
    color: rgba(255, 255, 255, 0.9);
    border-color: rgba(255, 255, 255, 0.6);
  }
`
