import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import Charades from './Charades'
import Start from './Start'
import { mount, route } from 'navi'
import { Router } from 'react-navi'
import styled from 'styled-components'

const includeAdult = new URLSearchParams(window.location.search).get('adult') !== null

const routes = mount({
  '/': route({
    title: 'Charadgeneratorn',
    view: <Start includeAdult={includeAdult} />
  }),
  '/play': route({
    title: 'Charadgeneratorn',
    view: <Charades includeAdult={includeAdult} />
  })
})

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #cc2b5e;
  background: linear-gradient(45deg, #753a88, #cc2b5e);
`

ReactDOM.render(
  <StyledWrapper>
    <Router routes={routes}>
      <App />
    </Router>
  </StyledWrapper>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
