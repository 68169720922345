import count from './count'
import adjective from './adjective'
import people from './people'
import verb from './verb'
import subjective from './subjective'
import location from './location'

export default {
  people,
  verb,
  adjective,
  subjective,
  count,
  location,
}
