import React, { useState } from 'react'
import styled from 'styled-components'
import { useSpring, animated, config, useTransition } from 'react-spring'
import { generateCharade } from './charadeGen'
import { DefaultButton } from './DefaultButton'

const StyledMain = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 16px;
`

const StyledHeading = styled.h1`
  position: absolute;
  color: white;
  max-width: 600px;
  font-size: 48px;
  text-align: center;
  text-shadow: 1px 1px rgba(0, 0, 0, 1);
  font-family: 'Kavoon', cursive;
  margin: 0;
  margin-top: auto;

  @media (max-width: 600px) {
    font-size: 36px;
  }
`

const AnimatedHeading = styled(animated(StyledHeading))``

const AnimatedButton = styled(animated(DefaultButton))`
  margin-top: auto;
  margin-bottom: 16px;
`

const initialState = generateCharade()

export default function Charades(props) {
  const [charade, setCharade] = useState(initialState)

  const buttonAnimation = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: config.molasses,
  })

  const transitions = useTransition(charade, charade, {
    from: { opacity: 0, transform: 'scale(0.5) translateY(-50%)' },
    enter: { opacity: 1, transform: 'scale(1) translateY(0)' },
    leave: { opacity: 0, transform: 'scale(0.5) translateY(50%)' },
  })

  return (
    <StyledMain>
      {transitions.map(({ item, props: style, key, state }) => (
        <AnimatedHeading
          key={key}
          style={{
            ...style,
          }}
        >
          {item}
        </AnimatedHeading>
      ))}
      <AnimatedButton
        style={buttonAnimation}
        onClick={() => {
          setCharade(generateCharade(props.includeAdult))
          window.fathom.trackGoal('J4H26HZZ', 0)
        }}
      >
        Ny charad
      </AnimatedButton>
    </StyledMain>
  )
}
