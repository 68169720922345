export default [
  { singular: 'folkskygg', plural: 'folkskygga' },
  { singular: 'otålig', plural: 'otåliga' },
  { singular: 'ledsen', plural: 'ledsna' },
  { singular: 'hungrig', plural: 'hungriga' },
  { singular: 'tjock', plural: 'tjocka' },
  { singular: 'norsk', plural: 'norska' },
  { singular: 'bredaxlad', plural: 'bredaxlade' },
  { singular: 'redlös', plural: 'redlösa' },
  { singular: 'blind', plural: 'blinda' },
  { singular: 'blöt', plural: 'blöta' },
  { singular: 'besatt', plural: 'besatta' },
  { singular: 'förvånad', plural: 'förvånade' },
  { singular: 'förbannad', plural: 'förbannade' },
  { singular: 'flirtig', plural: 'flirtiga' },
  { singular: 'uttråkad', plural: 'uttråkade' },
  { singular: 'nördig', plural: 'nördiga' },
  { singular: 'illaluktande', plural: 'illaluktande' },
  { singular: 'allergisk', plural: 'allergiska' },
  { singular: 'blyg', plural: 'blyga' },
  { singular: 'kärleksfull', plural: 'kärleksfulla' },
  { singular: 'rädd', plural: 'rädda' },
  { singular: 'gravid', plural: 'gravida' },
  { singular: 'generös', plural: 'generösa' },
  { singular: 'noggrann', plural: 'noggranna' },
  { singular: 'religiös', plural: 'religiösa' },
  { singular: 'obekväm', plural: 'obekväma' },
  { singular: 'klumpig', plural: 'klumpiga' },
  { singular: 'överviktig', plural: 'överviktiga' },
  { singular: 'chokladberoende', plural: 'chokladberoende' },
  { singular: 'cool', plural: 'coola' },
  { singular: 'deppig', plural: 'deppiga' },
  { singular: 'flintskallig', plural: 'flintskalliga' },
  { singular: 'genomskinlig', plural: 'genomskinliga' },
  { singular: 'fyrkantig', plural: 'fyrkantiga' },
  { singular: 'gammal', plural: 'gamla' },
  { singular: 'gift', plural: 'gifta' },
  { singular: 'muskulös', plural: 'muskulösa' },
  { singular: 'klibbig', plural: 'klibbiga' },
  { singular: 'mager', plural: 'magra' },
  { singular: 'mustaschprydd', plural: 'mustaschprydda' },
  { singular: 'nervös', plural: 'nervösa' },
  { singular: 'osynlig', plural: 'osynliga' },
  { singular: 'pensionerad', plural: 'pensionerade' },
  { singular: 'förkyld', plural: 'förkylda' },
  { singular: 'solbränd', plural: 'solbrända' },
  { singular: 'gnällig', plural: 'gnälliga' },
  { singular: 'vilsen', plural: 'vilsna' },
  { singular: 'förförisk', plural: 'förföriska' },
  { singular: 'ångestfylld', plural: 'ångestfyllda' },
  { singular: 'ängslig', plural: 'ängsliga' },
  { singular: 'andfådd', plural: 'andfådda' },
  { singular: 'uppsvälld', plural: 'uppsvällda' },
  { singular: 'beslutsam', plural: 'beslutsamma' },
  { singular: 'böjd', plural: 'böjda' },
  { singular: 'brittisk', plural: 'brittiska' },
  { singular: 'dammig', plural: 'dammiga' },
  { singular: 'fåfäng', plural: 'fåfänga' },
  { singular: 'fläckig', plural: 'fläckiga' },
  { singular: 'flexibel', plural: 'flexibla' },
  { singular: 'frusen', plural: 'frusna' },
  { singular: 'svettig', plural: 'svettiga' },
  { singular: 'grisig', plural: 'grisiga' },
  { singular: 'gosig', plural: 'gosiga' },
  { singular: 'hal', plural: 'hala' },
  { singular: 'hemlös', plural: 'hemlösa' },
  { singular: 'hatisk', plural: 'hatiska' },
  { singular: 'adopterad', plural: 'adopterade' },
  { singular: 'hysterisk', plural: 'hysteriska' },
  { singular: 'instängd', plural: 'instängda' },
  { singular: 'jättestor', plural: 'jättestora' },
  { singular: 'liten', plural: 'små' },
  { singular: 'kaxig', plural: 'kaxiga' },
  { singular: 'känslokall', plural: 'känslokalla' },
  { singular: 'vettskrämd', plural: 'vettskrämda' },
  { singular: 'kissnödig', plural: 'kissnödiga' },
  { singular: 'törstig', plural: 'törstiga' },
  { singular: 'apatisk', plural: 'apatiska' },
  { singular: 'magnetisk', plural: 'magnetiska' },
  { singular: 'maskulin', plural: 'maskulina' },
  { singular: 'feminin', plural: 'feminina' },
  { singular: 'långhårig', plural: 'långhåriga' },
  { singular: 'hårig', plural: 'håriga' },
  { singular: 'närsynt', plural: 'närsynta' },
  { singular: 'njutande', plural: 'njutande' },
  { singular: 'nysande', plural: 'nysande' },
  { singular: 'olycklig', plural: 'olyckliga' },
  { singular: 'försiktig', plural: 'försiktiga' },
  { singular: 'förvirrad', plural: 'förvirrade' },
  { singular: 'lat', plural: 'lata' },
  { singular: 'kylig', plural: 'kyliga' },
  { singular: 'pilsk', plural: 'pilska' },
  { singular: 'pantad', plural: 'pantade' },
  { singular: 'finnig', plural: 'finniga' },
  { singular: 'tatuerad', plural: 'tatuerade' },
  { singular: 'svag', plural: 'svaga' },
  { singular: 'stark', plural: 'starka' },
  { singular: 'larvig', plural: 'larviga' },
  { singular: 'erigerad', plural: 'erigerada', adult: true },
  { singular: 'fet', plural: 'feta', adult: true },
  { singular: 'naken', plural: 'nakna', adult: true },
  { singular: 'schizofren', plural: 'schizofrena', adult: true },
  { singular: 'snuskig', plural: 'snuskiga', adult: true },
  { singular: 'astmasjuk', plural: 'astmasjuka', adult: true },
  { singular: 'bakfull', plural: 'bakfulla', adult: true },
  { singular: 'döende', plural: 'döende', adult: true },
  { singular: 'handikappad', plural: 'handikappade', adult: true },
  { singular: 'upphetsad', plural: 'upphetsade', adult: true },
  { singular: 'kladdig', plural: 'kladdiga', adult: true },
]
