import React from 'react'
import styled from 'styled-components'
import { useSpring, useTrail, animated, config } from 'react-spring'
import { DefaultButton } from './DefaultButton'
import { useNavigation } from 'react-navi'

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
`

const StyledHeading = styled.h1`
  margin: 0;
  font-size: 62px;
  font-family: 'Kavoon', cursive;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa,
    0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3),
    0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.2),
    0 10px 10px rgba(0, 0, 0, 0.15);

  @media (max-width: 600px) {
    font-size: 36px;
  }
`

const AnimatedLetter = styled(animated.div)`
  display: inline-block;
  margin-right: 3px;
`

const AnimatedButton = styled(animated(DefaultButton))`
  margin-top: 36px;
`

const InfoText = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: white;
  line-height: 1.3;
  margin: 0;
  margin-top: 8px;
  opacity: 0.8;
  width: 100%;
`

const InfoWrapper = styled(animated.div)`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  max-width: 592px;
  align-items: center;
  padding: 0 16px;
`

export default function Start(props) {
  const navigation = useNavigation()

  const introAnimation = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: config.molasses,
    delay: 1000,
  })

  const title = 'Charadgeneratorn'
  const trail = useTrail(title.length, {
    opacity: 1,
    y: 0,
    from: { opacity: 0, y: -25 },
    config: { mass: 3, tension: 2000, friction: 90 },
    delay: 300,
  })

  return (
    <StyledMain>
      <StyledHeading>
        {trail.map(({ y, ...rest }, index) => {
          return (
            <AnimatedLetter
              key={index}
              style={{
                ...rest,
                transform: y.interpolate((x) => `translateY(${x}px)`),
              }}
            >
              {title[index]}
            </AnimatedLetter>
          )
        })}
      </StyledHeading>
      <InfoWrapper style={introAnimation}>
        <InfoText>
          Hej! Här kan du generera charader inspirerade av det klassiska TV-programmet "Gäster med
          gester" utan rädsla för att det roliga tar slut, det finns över 5 miljoner möjliga
          charader!
        </InfoText>
        <InfoText>
          Charaderna kan tyckas svåra men tro mig, det är både lättare och roligare än vad ni tror.
          Vill ni göra det lite enklare rekommenderar jag att någon agerar domare som ger ut lite
          ledtrådar och styr gissningar rätt, i lagom mängd.
        </InfoText>
        <InfoText>Lycka till och ha så kul!</InfoText>
        <AnimatedButton onClick={() => navigation.navigate('/play')}>
          Nu kör vi{props.includeAdult && ' 😏'}!
        </AnimatedButton>
      </InfoWrapper>
    </StyledMain>
  )
}
